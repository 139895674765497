import React from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import Logo from '~/logo/logo_full.png';
import { useEffect, useState } from 'react';
import { MenuIcon } from '@/assets/icons';
import Button from '@/components/button';
import cx from 'classnames';
import Link from 'next/link';
import { MENU } from '@/config-global';
import classNames from 'classnames';

const Header: React.FC = () => {
  const [isScroll, setScroll] = useState(false);
  const [toggle, setToggle] = useState(false);
  const router = useRouter();
  const { asPath } = router;

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 4) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    });
  }, []);

  const handleLogoClick = async () => {
    if (toggle) {
      setToggle(false);
    }
    await router.replace('/');
  };

  return (
    <>
      <header
        className={cx(
          'js-page-header fixed top-0 z-20 w-full lg:pb-4 transition-colors',
          {
            'js-page-header--is-sticky': isScroll
          }
        )}
      >
        <div className="flex items-center justify-between lg:px-8">
          <Link href="/" onClick={handleLogoClick}>
            <Image
              src={Logo}
              alt="Nestflo"
              className="w-[146px] lg:w-[200px]"
            />
          </Link>
          <div className="hidden flex-1 items-center justify-end space-x-5 pb-1 lg:flex">
            <nav className="navbar w-full lg:mt-4">
              <ul className="flex flex-col items-center justify-center lg:flex-row">
                {MENU.map((item, index) => (
                  <Link href={item.href} className="group relative" key={index}>
                    <button
                      className={classNames(
                        'uppercase text-jacarta-700 font-display hover:text-accent focus:text-accent hover:text-accent focus:text-accent flex w-full items-center justify-between !bg-clip-text py-3.5 text-base font-bold text-transparent  lg:px-5',
                        {
                          '[-webkit-background-clip:text] [-webkit-text-fill-color:transparent] [background:linear-gradient(180deg,_#ede6ff,_#D43A1A)]':
                            asPath === item.href,
                          '[-webkit-background-clip:text] [-webkit-text-fill-color:transparent] [background:linear-gradient(180deg,_#EDE5FF,_#0B6A99)]':
                            asPath !== item.href
                        }
                      )}
                      type="button"
                    >
                      <span className="text-accent">{item.title}</span>
                    </button>
                  </Link>
                ))}
              </ul>
            </nav>
          </div>
          <Button
            variant="outline"
            className="mr-4 mt-3 hidden pr-7 uppercase tracking-[0.5em] md:mr-0 lg:block"
            onClick={() => {
              window.location.href =
                'https://calendly.com/roland-tao-55/30min?month=2024-02';
            }}
          >
            Book Demo
          </Button>
          <div className="ml-auto flex justify-center lg:hidden">
            <button
              className="js-mobile-toggle"
              aria-label="open mobile menu"
              onClick={() => setToggle(true)}
            >
              <MenuIcon
                className="text-gray-400 -ml-1 mr-4 size-12"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      </header>
      <div
        className={`js-mobile-menu menu-bg invisible fixed inset-0 z-20 ml-auto items-center bg-dark opacity-0 transition duration-500 ease-in-out lg:visible lg:relative lg:inset-auto lg:hidden lg:bg-transparent lg:opacity-100 ${
          toggle ? 'nav-menu--is-open' : 'hidden'
        }`}
      >
        <div className="t-0 fixed left-0 z-10 flex w-full items-center justify-center lg:hidden">
          <Link href="/" onClick={handleLogoClick}>
            <Image
              src={Logo}
              alt="Nestflo"
              className="w-[146px] lg:w-[200px]"
            />
          </Link>
        </div>
        <button
          className="absolute right-4 top-4 z-50"
          onClick={() => setToggle(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-6 text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>

        <nav className="navbar mt-24 w-full">
          <ul className="flex flex-col lg:flex-row">
            {MENU.map((item, index) => (
              <li
                className="group relative text-center"
                onClick={() => setToggle(false)}
                key={index}
              >
                <Link
                  href={item.href}
                  // className={classNames(
                  //   'uppercase text-jacarta-700 font-display hover:text-accent focus:text-accent hover:text-accent focus:text-accent flex w-full items-center justify-center !bg-clip-text py-3.5 text-base font-bold text-transparent lg:px-5',
                  //   {
                  //     '[-webkit-background-clip:text] [-webkit-text-fill-color:transparent] [background:linear-gradient(180deg,_#ede6ff,_#D43A1A)]':
                  //       asPath === item.href,
                  //     '[-webkit-background-clip:text] [-webkit-text-fill-color:transparent] [background:linear-gradient(180deg,_#EDE5FF,_#0B6A99)]':
                  //       asPath !== item.href
                  //   }
                  // )}
                  className={classNames(
                    'uppercase font-display hover:text-accent focus:text-accent hover:text-accent focus:text-accent flex w-full items-center justify-center !bg-clip-text py-3.5 text-base font-bold lg:px-5',
                    {
                      'text-[#D43A1A]': asPath === item.href,
                      'text-[#0B6A99]': asPath !== item.href
                    }
                  )}
                >
                  <span className="text-center">{item.title}</span>
                </Link>
              </li>
            ))}
            <li
              className="group relative text-center"
              onClick={() => setToggle(false)}
            >
              <Button
                variant="outline"
                className=" mt-6  uppercase tracking-[0.5em]"
                onClick={() => {
                  window.location.href =
                    'https://calendly.com/roland-tao-55/30min?month=2024-02';
                }}
              >
                Book Demo
              </Button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Header;
